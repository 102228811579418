<template>
	<div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
		<side-bar
			:background-color="sidebarBackground"
			short-title="Opus"
			title="Opus"
		>
			<template slot="links">
				<sidebar-item
					:link="{
						name: 'Bookings',
						icon: 'fa fa-list text-danger',
						path: '/users',
					}"
				/>
				<sidebar-item
					v-if="sidebar === 'admin'"
					:link="{
						name: 'Staff',
						icon: 'fa fa-users text-danger',
						path: '/staff',
					}"
				/>
				<!-- <sidebar-item
					v-if="sidebar === 'admin'"
					:link="{
						name: 'App Users',
						icon: 'fa fa-user text-danger',
						path: '/app-user',
					}"
				/> -->
				<sidebar-item
					
					:link="{
						name: 'App Users',
						icon: 'fa fa-user text-danger',
						path: '/app-user',
					}"
				/>
				<sidebar-item
					v-if="sidebar === 'admin'"
					:link="{
						name: 'Settings',
						icon: 'fa fa-cog text-danger',
						path: '/settings',
					}"
				/>
			</template>
		</side-bar>
		<div class="main-content" :data="sidebarBackground">
			<dashboard-navbar></dashboard-navbar>

			<div @click="toggleSidebar">
				<loader />
				<fade-transition
					:duration="200"
					origin="center top"
					mode="out-in"
				>
					<!-- your content here -->
					<router-view></router-view>
				</fade-transition>
				<content-footer v-if="!$route.meta.hideFooter"></content-footer>
			</div>
		</div>
	</div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";

export default {
	components: {
		DashboardNavbar,
		ContentFooter,
		FadeTransition,
	},
	data() {
		return {
			sidebarBackground: "vue", //vue|blue|orange|green|red|primary
			permissionData: {},
		};
	},
	computed: {
		sidebar() {
			return localStorage.getItem("user_type");
		},

		userID() {
			return localStorage.getItem("user-id");
		},
	},
	methods: {
		toggleSidebar() {
			if (this.$sidebar.showSidebar) {
				this.$sidebar.displaySidebar(false);
			}
		},
	},
	mounted() {
		if (this.sidebar !== "admin") {
			if (this.userID !== null) {
				this.$store
					.dispatch("fetchStaffPermissionDetails", this.userID)
					.then(() => {
						this.permissionData = JSON.parse(
							JSON.stringify(
								this.$store.state.StaffData
									.staffPermissionDetails
							)
						);
					})
					.catch((err) => {
						this.$notify({
							title: "Failed to fetch user details",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: err.toString(),
						});
					});
			}
		}
	},
};
</script>
<style lang="scss"></style>
