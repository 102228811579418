var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"Opus","title":"Opus"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
					name: 'Bookings',
					icon: 'fa fa-list text-danger',
					path: '/users',
				}}}),(_vm.sidebar === 'admin')?_c('sidebar-item',{attrs:{"link":{
					name: 'Staff',
					icon: 'fa fa-users text-danger',
					path: '/staff',
				}}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
					name: 'App Users',
					icon: 'fa fa-user text-danger',
					path: '/app-user',
				}}}),(_vm.sidebar === 'admin')?_c('sidebar-item',{attrs:{"link":{
					name: 'Settings',
					icon: 'fa fa-cog text-danger',
					path: '/settings',
				}}}):_vm._e()],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('loader'),_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }